

















import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      formState: {
        name: '',
        gender: '0',
        birth: '',
        avatar: '',
      },
      itemConfig: [
        {
          type: 'input',
          itemConfig: {
            label: '姓名',
            prop: 'name',
            width: '100%',
            rules: { required: true, message: '请输入姓名', trigger: 'blur' },
          },
        },
        {
          type: 'upload',
          slot: true,
          itemConfig: {
            label: '头像',
            prop: 'avatar',
            width: '100%',
            rules: { required: true, message: '请上传头像', trigger: 'change' },
          },
        },
        {
          type: 'radio',
          itemConfig: {
            label: '性别',
            prop: 'gender',
            width: '100%',
            rules: { required: true, message: '请输入性别', trigger: 'change' },
          },
          innerConfig: {
            options: [
              { label: '男', value: '0' },
              { label: '女', value: '1' },
            ],
          },
        },
        {
          type: 'date',
          itemConfig: {
            label: '出生日期',
            prop: 'birth',
            width: '100%',
            rules: {
              required: true,
              message: '请选择出生日期',
              trigger: 'change',
            },
          },
        },
      ],
    };
  },
});
